<template>
  <div>

  </div>
</template>

<script>

  export default {
    name: 'UserLeftMenu',
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      }
    },
    methods: {
      userSignOut () {
        this.$store.dispatch('userSignOut')
      }
    }
  };
</script>
